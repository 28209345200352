import React from 'react';
import Notifications from "react-notification-system-redux";
import {API_URL} from "./constants_api";
import {ApiPaths, querySerialize} from "./Api";

const addSwitchUserHeader=(headers, options)=>{
    if(localStorage.switchMe && !options.config.preventUserSwitch)
        headers["x-view-analytics"]= window.localStorage.switchMe;
};


const config ={
    host:API_URL,
    commonPath:'api',
    credentials:'include',
    getDataFromResponse:(r)=>r?.data || r,
    getMetaDataFromResponse:(r)=>r.meta,
    appendHeaders:addSwitchUserHeader,
    initializeFromLocalStorage:false,//This is because we're merging the states with the old api
    onError:errorHandler,
    strictMode: false,
    endpoints:[
        'jazzAreaBudgetLogs',
        'loungeAccessMethods',
        'loungeMaps',
        'orozcoProductCategories',
        'purchaseInvoices',
        'purchasePayments',
        'stockMovementBatches',
        'users',
        'visits',
        'warehouses',
        {
            name:'appFiles',
            customMethods:{
                getUrl: function(id){ return `${this.host}${ApiPaths.appFileUrl}/${id}`},
                getDownloadUrl: function(id){ return `${this.host}${ApiPaths.appFileDownload}/${id}`},
            }
        },
        {
            name:'guests',
            customMethods:{
                numberInPeriod: function({params={}, ...config}={}){
                    return this.apiCall('guests/number_in_period', '_', params||{}, config);
                },
                homologateGuests: function ({params={}, ...config} = {}) {
                    return this.apiCall('guests/homologate', '_', params || {}, config);
                }
            }
        },
        {
            name:'inventories',
            customMethods:{
                forStockRequest: function({params={}, ...config}={}){
                    return this.apiCall('inventories/for_stock_request', '_', params||{}, config);
                },
            }
        },
        {
            name:'purchaseRequisitions',
            customMethods:{
                getMyPendingPurchaseRequisitions: function ( { params, customProp,  ...config } ){
                    return this.apiCall('purchase_requisitions/pending_approved_by_me', customProp || "purchaseRequisitions", params, config)},
            }
        },
        {
            name: 'scoresGenerationsBiweekly',
            customMethods: {
                getEmployeesQualifications: function ({params={}, ...config}={}) {
                    return this.apiCall('scores_generations/biweekly', '_', params||{}, config);
                },
                downloadReport: function ({params}) {
                    return this.host + ApiPaths.scoresGenerationsBiweeklyReport + '?' + querySerialize(params);
                }
            }
        },
        {
            name: 'acknowledgeNewPassword',
            customMethods: {
                confirmNewPassword: function ({params={}, ...config}={}) {
                    return this.apiCall('me/acknowledge_new_password', '_', params || {}, config);
                }
            }
        },
        {
            name: 'golfCartTrips',
            customMethods: {
                downloadTripsReport: function ({params}) {
                    return this.host + ApiPaths.golfCartTrips + '.xlsx?' + querySerialize(params);
                }
            }
        },
        {
            name: 'employees',
            customMethods: {
                getByPermissions: function ({params, customProp, ...config}={}) {
                    return this.apiCall('employees/search', customProp || "employeesList", params, config);
                },
                getSimple: function ({params, customProp, ...config}={}) {
                    return this.apiCall('employees_simple', customProp || "employees", params, config);
                }
            }
        }
    ],
};

function errorHandler(response){

    let message = response instanceof Error?
        "Error de red. Revisa tu conexión a internet":
        "Parece que hubo un error.";
    // Symfony profiler
    if(response && response.headers && response.headers.get('X-Debug-Token-Link')) {
        const debugLink = response.headers.get('X-Debug-Token-Link');
        message = <span>{message} <a target="_blank" rel="noopener noreferrer" href={debugLink+'?panel=exception'}>{debugLink}</a></span>;
    }
    this.store.dispatch( Notifications.error({
        title: <i className="fa fa-exclamation-triangle"/>,
        message: <span> {message} <br/> {response && response.detail?response.detail:null}</span>,
        autoDismiss: 8
    }));
}

if(process.env.REACT_APP_BUILD === 'dev')
    config.host=localStorage.host||'https://gledev.tide.company';

if(process.env.REACT_APP_BUILD === 'staging')
    config.host='https://davinci-pruebas-back.tide.company';

export default config;

export const TideApiContext = React.createContext(null);
