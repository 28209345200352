//App constants

export let API_URL = "https://back.tgle.mx";

export let SOCKET_API_URL = "http://tgle-app.tide.mx:1337";

//Actions prefix
export const ACTION_PREFIX = "@API_";

//Actions suffixes
export const ACTION_LOG = "LOG_APP";
//This actions say how to save the obtained data to the store
export const STATE_ACTION_PUSH = "PUSH";//Push to an array of items
export const STATE_ACTION_PREPEND = "PREPEND";//Prepend an element at the start of another array of items
export const STATE_ACTION_APPEND = "APPEND";//Append an array at the end of another array of items
export const STATE_ACTION_SET = "SET";//Set the data in the response as the object in the state
export const STATE_ACTION_SET_SINGLE = "SET_SINGLE";//Set the data in the response inside the state object with id as key
export const STATE_ACTION_SEARCH_N_DELETE = "SEARCH_N_DELETE";//Search the object in an array in the store and remove it
export const STATE_ACTION_SEARCH_N_REPLACE = "SEARCH_N_REPLACE";//Search the object in an array in the store and replace it with the one in the response
export const STATE_ACTION_CLEAR = "CLEAR";//Set corresponding state piece to undefined

//Cache actions
export const ACTION_CACHE_SAVE = 'CACHE_SAVE';//Save result from request to cache

//Queue actions
export const ACTION_QUEUE_PUSH = 'API_QUEUE_PUSH';//Queue request to be sent when network is available
export const ACTION_QUEUE_SHIFT = 'API_QUEUE_SHIFT';//Queue request to be sent when network is available

export const QUEUED_RESPONSE = 'QUEUED_RESPONSE';//This is returned by a failed request which was queued to be sent later

export const QUEUE_INTERVAL = 15000;//Interval to check if connection is available and resend failed queued requests

//States

export const LOGIN_STATE = {
    NOT_LOGGED: "NOT_LOGGED",
    LOGGED_IN: "LOGGED_IN",
    BAD_CREDENTIALS: "BAD_CREDENTIALS",
    LOGIN_ERROR: "LOGIN_ERROR",
};

export const initialState = {
    loggedIn: LOGIN_STATE.NOT_LOGGED,
};

export const initialCache = {};
export const initialQueue = [];

if( process.env.REACT_APP_BUILD === 'dev' ) {
    console.log("YOU ARE IN DEVELOPMENT MODE");

    //    window.localStorage.timeTravel = '2018-04-30';
    API_URL = window.localStorage.host || "https://nom-back.tide.mx";
    SOCKET_API_URL = window.localStorage.socketHost || "http://localhost:1337";
    console.log("Time travel is active");//Go to Api.js -> L: 6, 30, 31, 98, 102, 155, 156, 348, 349
}

if( process.env.REACT_APP_BUILD === 'davinci' ) {
    console.log("YOU ARE IN STAGING MODE");

    //    window.localStorage.timeTravel = '2018-04-30';
    API_URL = window.localStorage.host || "https://davinci-pruebas-back.tide.company";
    SOCKET_API_URL = window.localStorage.socketHost || "http://localhost:1337";
    console.log("Time travel is active");//Go to Api.js -> L: 6, 30, 31, 98, 102, 155, 156, 348, 349
}



